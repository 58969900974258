import React from 'react'

export default function Tokenomics() {
    return (
        <>
            <section className="about padding-top--style2 padding-bottom bg-color-3" id='Tokenomics'>
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="section-header mb-50">
                                    <h2>Tokenomics</h2>
                                </div>
                                <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={900}>
                                    <div className="about__content-inner">
                                        {/* <div className="about__icon">
                                            <div className="about__icon-inner">
                                                <img src="file:///C:/My%20Web%20Sites/bittrader/thetork.com/demos/html/bitrader/assets/images/about/icon/2.png" alt="about-icon" className="dark" />
                                            </div>
                                        </div> */}
                                        <div className="about__content-details">
                                            <p className="mb-0">Token Name :</p>
                                            <h5>  Pippin</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="about__content-inner">
                                        {/* <div className="about__icon">
                                            <div className="about__icon-inner">
                                                <img src="file:///C:/My%20Web%20Sites/bittrader/thetork.com/demos/html/bitrader/assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                            </div>
                                        </div> */}
                                        <div className="about__content-details">
                                            <p className="mb-0">Token Ticker :</p>
                                            <h5>PIPN</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="about__content-inner">
                                        {/* <div className="about__icon">
                                            <div className="about__icon-inner">
                                                <img src="file:///C:/My%20Web%20Sites/bittrader/thetork.com/demos/html/bitrader/assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                            </div>
                                        </div> */}
                                        <div className="about__content-details">
                                            <p className="mb-0">Total Supply :</p>
                                            <h5>2000 Million</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="about__content-inner">
                                        {/* <div className="about__icon">
                                            <div className="about__icon-inner">
                                                <img src="file:///C:/My%20Web%20Sites/bittrader/thetork.com/demos/html/bitrader/assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                            </div>
                                        </div> */}
                                        <div className="about__content-details">
                                            <p className="mb-0">Contract : </p>
                                            <h5><a href="https://bscscan.com/token/0x8f5cf9d94cc0c3a9f214134054a0c02bd2ca00d1" target='_balnk'>0x8f5cf9d94cc0c3a9f214134054a0c02bd2ca00d1</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="about__content-inner">
                                        {/* <div className="about__icon">
                                            <div className="about__icon-inner">
                                                <img src="file:///C:/My%20Web%20Sites/bittrader/thetork.com/demos/html/bitrader/assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                            </div>
                                        </div> */}
                                        <div className="about__content-details">
                                            <p className="mb-0">Chain:</p>
                                            <h5>Binance Smart Chain</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__thumb about__thumb--style2 aos-init aos-animate" data-aos="fade-left" data-aos-duration={800}>
                                    <div className="about__thumb-inner mt-30 mt-lg-0">
                                        <div className="about__thumb-image  text-center">
                                            <img src="./assets/images/tokenomics.png" alt="about-image" className="dark" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about__shape about__shape--style2">
                    <span className="about__shape-item about__shape-item--1"><img src="assets/images/others/2.png" alt="shape-icon" /></span>
                </div>
            </section>
        </>

    )
}
