import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import About from './About'
import Benifit from './Benifit'
import Problame from './Problame'
import Whitepapaer from './Whitepapaer'
import MobileApp from './MobileApp'
import Roadmap from './Roadmap'
import Calculate from './Calculate'
import Tokenomics from './Tokenomics'
import Client from './Client'
import Faq from './Faq'
import BuildYourCrypto from './BuildYourCrypto'

export default function Index() {
    return (
        <>
            <Header />
            <Hero />
            <Client />
            <About />
            <Benifit />
            <Problame />
            <Whitepapaer />
            <BuildYourCrypto />
            {/* <MobileApp /> */}
            <Roadmap />
            {/* <Calculate /> */}
            <Tokenomics />
            <Faq />
            <Footer />
        </>
    )
}
