import React from 'react'

export default function Footer() {
    return (
        <>
            <div>
                <footer className="footer" style={{backgroundImage:`url('./assets/images/footer.png')`, backgroundRepeat:"no-repeat", paddingTop:"10px", paddingBottom:"10px",backgroundSize:"cover", backgroundPosition:"50% 50%"}}>
                    <div className="container">
                        <div className="footer__wrapper  ">
                            <div className='d-flex justify-content-center py-4'>
                                <ul className="social">
                                    <li className="social__item">
                                        <a href="#" className="social__link social__link--style22"><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="#" className="social__link social__link--style22 "><i className="fab fa-instagram" /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="#" className="social__link social__link--style22"><i className="fa-brands fa-linkedin-in" /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="#" className="social__link social__link--style22"><i className="fab fa-youtube" /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="#" className="social__link social__link--style22 "><i className="fab fa-twitter" /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer__bottom">
                                <div className="footer__end justify-content-center">
                                    <div className="footer__end-copyright">
                                        <p className="mb-0 text-center">© 2024 All Rights Reserved By Pippin </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <div className="footer__shape">
                        <span className="footer__shape-item footer__shape-item--1"><img src="assets/images/footer/1.png" alt="shape icon" /></span>
                        <span className="footer__shape-item footer__shape-item--2"> <span /> </span>
                    </div> */}
                </footer>
                {/* ===============>> footer end here <<================= */}
                {/* ===============>> scrollToTop start here <<================= */}
                <a href="#" className="scrollToTop scrollToTop--style1"><i className="fa-solid fa-arrow-up-from-bracket" /></a>
            </div>
        </>
    )
}
