import React from 'react'

export default function Header() {
    return (
        <>
            <header className="header-section header-section--style2">
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <a href="/">
                                    <img className="dark" src="assets/images/logo/logo.png" width="200" alt="logo" />
                                </a>
                            </div>
                            <div className="menu-area">
                                <ul className="menu menu--style1">
                                    <li className="megamenu">
                                        <a href="#About">About</a>
                                    </li>
                                    <li>
                                        <a href="#Whitepaper">Whitepaper</a>
                                    </li>
                                    <li>
                                        <a href="#Roadmap">Roadmap</a>
                                    </li>
                                    <li>
                                        <a href="#Tokenomics">Tokenomics</a>
                                    </li>
                                    <li>
                                        <a href="#Faq">FAQ</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="header-action">
                                <div className="menu-area">
                                    <div className="header-btn">
                                        <a href="" className="trk-btn trk-btn--border trk-btn--primary">
                                            <span>Start Now</span>
                                        </a>
                                    </div>
                                    {/* toggle icons */}
                                    <div className="header-bar d-lg-none header-bar--style1">
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
