import React from 'react'

export default function Roadmap() {
    return (
        <>
            <section className="roadmap roadmap--style2 padding-top  padding-bottom bg-color" id='Roadmap'>
                <div className="container">
                    <div className="section-header section-header--max50">
                        <h2 className="mb-10 mt-minus-5"> <span className="style2">Futuristic Roadmap</span></h2>
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="roadmap__upper">
                            <div className="roadmap__upper-inner">
                                <div className="swiper overflow-auto">
                                    <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                        <div className="swiper-wrapper" id="swiper-wrapper-a0686e73c07b5ce1" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                            <div className="swiper-slide swiper-slide-" role="group" aria-label="1 / 6" style={{ width: '279px', marginRight: '10px' }}>
                                                <div className="roadmap__item ">
                                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                        <div className="roadmap__item-content">
                                                            <h5>Q1 2024</h5>
                                                            <p>
                                                                <ul className='text-start'>
                                                                    <li>1. Listing on Tracking Platforms</li>
                                                                    <li>2. Collaboration with News & Media platforms</li>
                                                                    <li>3. PIPN Smart Contract Audit</li>
                                                                    <li> 4. Staking Process Starts Launching</li>
                                                                    <li>5. Technical research papers for Pippin Blockchain</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 6" style={{ width: '279px', marginRight: '10px' }}>
                                                <div className="roadmap__item roadmap__item--reverse">
                                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                        <div className="roadmap__item-content">
                                                            <h5>Q2 2024</h5>
                                                            <p>
                                                                <ul className='text-start'>
                                                                    <li>1. E-commerce Platform Launch</li>
                                                                    <li>2. PIPN Listing on second Exchange </li>
                                                                    <li>3. PIPN Staking Plans</li>
                                                                    <li>4. Exchange Development Starts </li>
                                                                    <li>5. Blockchain Whiteppaer Launch</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide" role="group" aria-label="3 / 6" style={{ width: '279px', marginRight: '10px' }}>
                                                <div className="roadmap__item">
                                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                        <div className="roadmap__item-content">
                                                            <h5>Q3 2024</h5>
                                                            <p>
                                                                <ul className='text-start'>
                                                                    <li>1. Partnership & integration with Payment Gateways</li>
                                                                    <li>2. Mobile Wallet application development starts </li>
                                                                    <li>3. Security Audits</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide" role="group" aria-label="4 / 6" style={{ width: '279px', marginRight: '10px' }}>
                                                <div className="roadmap__item roadmap__item--reverse">
                                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                        <div className="roadmap__item-content">
                                                            <h5>Q4 2024</h5>
                                                            <p>
                                                                <ul className='text-start'>
                                                                    <li>1. Exchange Launch </li>
                                                                    <li>2. Mobile Wallet application Launch  </li>
                                                                    <li>3. Liquidity Provider Collaborations for Exchange </li>
                                                                    <li>4. Pippin Blockchain Testnet Launch </li>
                                                                    <li>5. Node Incentive programs for Testnet</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-slide" role="group" aria-label="5 / 6" style={{ width: '279px', marginRight: '10px' }}>
                                                <div className="roadmap__item">
                                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                        <div className="roadmap__item-content">
                                                            <h5>Q1 2025</h5>
                                                            <p>
                                                                <ul className='text-start'>
                                                                    <li>1. Blockchain Launch  </li>
                                                                    <li>2. SJC Blockchain Explorer </li>
                                                                    <li>3. Blockchain Integrations to Exchanges </li>
                                                                    <li>4. PIPN Smart Contract Migration to Pippin Blockchain  </li>
                                                                    <li>5. First Ecosystem Grant Release</li>
                                                                    <li>6. PIPN Blockchain Wallet Partnerships</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
