import React from 'react'

export default function Benifit() {
    return (
        <>
            <section className="service padding-top padding-bottom">
                <div className="section-header section-header--max50">
                    <h2 className="mb-10 mt-minus-5"><span>Competitive </span>Benefit</h2>
                </div>
                <div className="container">
                    <div className="service__wrapper">
                        <div className="row g-4 align-items-center">
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="service__item service__item--style1" data-aos="fade-up" data-aos-duration={800}>
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <img className="dark" src="assets/images/secure_transaction.png" alt="service-icon" />
                                        </div>
                                        <div className="service__item-content">
                                            <h5> <a className="stretched-link">Secure Transaction</a> </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="service__item service__item--style1" data-aos="fade-up" data-aos-duration={1000}>
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <img className="dark" src="assets/images/powerful_ecosystem.png" alt="service-icon" />
                                        </div>
                                        <div className="service__item-content">
                                            <h5> <a className="stretched-link"> Powerful Ecosystem</a> </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="service__item service__item--style1" data-aos="fade-up" data-aos-duration={1200}>
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <img className="dark" src="assets/images/instant_transaction.png" alt="service-icon" />
                                        </div>
                                        <div className="service__item-content">
                                            <h5> <a className="stretched-link">Instant Transaction
                                            </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="service__item service__item--style1" data-aos="fade-up" data-aos-duration={800}>
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <img className="dark" src="assets/images/easy_convenient_system.png" alt="service-icon" />
                                        </div>
                                        <div className="service__item-content">
                                            <h5> <a className="stretched-link">Easy & Convenient System</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="service__item service__item--style1" data-aos="fade-up" data-aos-duration={1000}>
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <img className="dark" src="assets/images/smart_web.png" alt="service-icon" />
                                        </div>
                                        <div className="service__item-content">
                                            <h5> <a className="stretched-link">Smart Web</a> </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="service__item service__item--style1" data-aos="fade-up" data-aos-duration={1200}>
                                    <div className="service__item-inner text-center">
                                        <div className="service__item-thumb mb-30">
                                            <img className="dark" src="assets/images/complete_decentralize.png" alt="service-icon" />
                                        </div>
                                        <div className="service__item-content">
                                            <h5> <a className="stretched-link" href="service-details.html">Complete Decentralize</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
