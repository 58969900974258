import React from 'react'
import Marquee from "react-fast-marquee";

export default function Client() {
    return (
        <>
            <div className="partner partner--gradient">
                <div className="container">
                    <div className="partner__wrapper">
                        <div className="partner__slider swiper swiper-initialized swiper-horizontal swiper-pointer-events">
                            <Marquee>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 5" style={{ width: '170.167px', marginRight: '25px' }}>
                                    <div className="partner__item">
                                        <div className="partner__item-inner">
                                            <img src="./assets/images/metamask.png" alt="partner logo" className="dark" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 5" style={{ width: '170.167px', marginRight: '25px' }}>
                                    <div className="partner__item">
                                        <div className="partner__item-inner">
                                            <img src="./assets/images/coingecko.png" alt="partner logo" className="dark" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 5" style={{ width: '170.167px', marginRight: '25px' }}>
                                    <div className="partner__item">
                                        <div className="partner__item-inner">
                                            <img src="./assets/images/coinjournal.png" alt="partner logo" className="dark" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 5" style={{ width: '170.167px', marginRight: '25px' }}>
                                    <div className="partner__item">
                                        <div className="partner__item-inner">
                                            <img src="./assets/images/coinmarketcap.png" alt="partner logo" className="dark" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 5" style={{ width: '170.167px', marginRight: '25px' }}>
                                    <div className="partner__item">
                                        <div className="partner__item-inner">
                                            <img src="./assets/images/pancakeswap.png" alt="partner logo" className="dark" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index={1} role="group" aria-label="2 / 5" style={{ width: '170.167px', marginRight: '25px' }}>
                                    <div className="partner__item">
                                        <div className="partner__item-inner">
                                            <img src="./assets/images/trustwallet.png" alt="partner logo" className="dark" />
                                        </div>
                                    </div>
                                </div>
                            </Marquee>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
