import React from 'react'

export default function Problame() {
    return (
        <>
            <section className="about about--style3 padding-top padding-bottom ">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5">
                            <div className="col-md-6">
                                <div className="about__thumb about__thumb--style4 aos-init aos-animate" data-aos="fade-right" data-aos-duration={800}>
                                    <div className="about__thumb-inner">
                                        <div className="about__thumb-image text-center floating-content">
                                            <img className="dark" src="./assets/images/problem.png" alt="about-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about__content aos-init aos-animate" data-aos="fade-left" data-aos-duration={800}>
                                    <div className="about__content-icon">
                                        <img src="assets/images/banner/home3/2.png" alt="trade icon" />
                                    </div>
                                    <h2>PROBLEMS</h2>
                                    <p className="mb-0">The ownership of our data is a pivotal aspect of this transition, allowing individuals to assert control over what they create and possess across diverse platforms. Yet, the question arises: How do we unite these fragmented pieces into a cohesive and transformative framework?</p>
                                    <p className="mb-0">
                                        The task at hand involves not just claiming ownership but orchestrating a synthesis that transcends individual platforms. A comprehensive solution is needed to weave together these disparate elements, forging a unified and robust structure for the emergent Web 3. This challenge beckons for innovative approaches and collaborative efforts to create a seamlessly interconnected digital landscape.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 mt-100">
                            <div className="col-md-6">
                                <div className="about__content aos-init aos-animate" data-aos="fade-right" data-aos-duration={800}>
                                    <h2>SOLUTIONS</h2>
                                    <p className="mb-0">Decentralized cryptocurrency is generated collaboratively by the entire cryptocurrency network, adhering to a predefined issuance rate established during the system's inception. This information is transparent and publicly accessible, contributing to the open nature of the cryptocurrency ecosystem.</p>
                                    <p className="mb-0">
                                    n contrast, centralized banking structures, exemplified by entities like the Federal Reserve System, witness control over currency supply resting in the hands of corporate boards or governmental authorities. These entities exercise their influence by either physically printing fiat money or authorizing digital entries in centralized banking ledgers. The control exerted by such centralized bodies stands in stark contrast to the decentralized and collectively governed nature of cryptocurrency issuance.0</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about__thumb about__thumb--style4 aos-init aos-animate" data-aos="fade-left" data-aos-duration={800}>
                                    <div className="about__thumb-inner">
                                        <div className="about__thumb-image text-center floating-content">
                                            <img className="dark" src="./assets/images/solutions.png" alt="about-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}
