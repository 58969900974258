import React from 'react'

export default function Faq() {
    return (
        <>
            <section className="faq padding-top padding-bottom of-hidden">
                <div className="section-header section-header--max65">
                    <h2 className="mb-10 mt-minus-5"><span>Frequently</span> Asked questions</h2>
                    <p>Hey there! Got questions? We've got answers. Check out our FAQ page for all the deets. Still not satisfied? Hit
                        us up.</p>
                </div>
                <div className="container">
                    <div className="faq__wrapper">
                        <div className="row g-5 align-items-center justify-content-center">
                            <div className="col-lg-10">
                                <div className="accordion accordion--style1 aos-init" id="faqAccordion1" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item">
                                                <div className="accordion__header accordion-header" id="faq1">
                                                    <button className="accordion__button accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody1" aria-expanded="false" aria-controls="faqBody1">
                                                        <span className="accordion__button-content">How can i Buy PIPN token?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody1" className="accordion-collapse collapse" aria-labelledby="faq1" data-bs-parent="#faqAccordion1" style={{}}>
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15">
                                                            PIPN token is listed on PancakeSwap Exchange. So, you can buy PIPN token through PancakeSwap exchange by USDT.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item">
                                                <div className="accordion__header accordion-header" id="faq2">
                                                    <button className="accordion-button accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody2" aria-expanded="false" aria-controls="faqBody2">
                                                        <span className=" accordion__button-content">When would I be able to see my token balance?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody2" className="accordion-collapse collapse" aria-labelledby="faq2" data-bs-parent="#faqAccordion1">
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15">
                                                            Once the transaction is confirmed by the supported blockchain network, you will be able to see your token balance.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item">
                                                <div className="accordion__header accordion-header" id="faq3">
                                                    <button className="accordion-button accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody3" aria-expanded="false" aria-controls="faqBody3">
                                                        <span className="accordion__button-content">Is PIPN having any staking Program?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody3" className="accordion-collapse collapse" aria-labelledby="faq3" data-bs-parent="#faqAccordion1">
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15"> Yes, PIPN is having decentralized staking program. This staking program gives you opportunity to earn More 180% APR.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item">
                                                <div className="accordion__header accordion-header" id="faq4">
                                                    <button className="accordion-button accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody4" aria-expanded="false" aria-controls="faqBody4">
                                                        <span className="accordion__button-content">How frequently will I receive staking rewards?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody4" className="accordion-collapse collapse" aria-labelledby="faq4" data-bs-parent="#faqAccordion1">
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15"> You will receive your staking rewards on daily basis as per the token current rate.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item">
                                                <div className="accordion__header accordion-header" id="faq5">
                                                    <button className="accordion-button accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody5" aria-expanded="false" aria-controls="faqBody5">
                                                        <span className="accordion__button-content">Is there any restriction involving a minimum or a maximum harvest limit?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody5" className="accordion-collapse collapse" aria-labelledby="faq5" data-bs-parent="#faqAccordion1">
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15"> Yes, you can harvest your staking rewards once you reach $ 10 amount.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item border-bottom">
                                                <div className="accordion__header accordion-header" id="faq6">
                                                    <button className="accordion-button accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody6" aria-expanded="false" aria-controls="faqBody6">
                                                        <span className="accordion__button-content">
                                                            Who manage PIPN Staking platform?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody6" className="accordion-collapse collapse" aria-labelledby="faq6" data-bs-parent="#faqAccordion1">
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15">As we explained in earlier question that PIPN is decentralized staking program. So, it works through the Smart Contracts (it has no Management or admins). The platform is fully decentralized but at the same time highly secure because it is made through the process of cryptography.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item accordion-item border-0">
                                                <div className="accordion__header accordion-header" id="faq7">
                                                    <button className="accordion-button accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody7" aria-expanded="false" aria-controls="faqBody7">
                                                        <span className="accordion__button-content">
                                                            What is Smart contract?</span>
                                                    </button>
                                                </div>
                                                <div id="faqBody7" className="accordion-collapse collapse" aria-labelledby="faq7" data-bs-parent="#faqAccordion1">
                                                    <div className="accordion__body accordion-body">
                                                        <p className="mb-15">A smart contract– it’s a digital trade agreement. These agreements will run automatically when certain conditions are met and deployed to blockchain.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faq__shape faq__shape--style1">
                    <span className="faq__shape-item faq__shape-item--1"><img src="assets/images/others/2.png" alt="shpae-icon" /></span>
                </div>
            </section>

        </>
    )
}
