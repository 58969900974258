import React from 'react'

export default function About() {
    return (
        <>
            <section className="about about--style1" id='About'>
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row gx-5  gy-4 gy-sm-0  align-items-center">
                            <div className="col-lg-6">
                                <div className="about__thumb pe-lg-5" data-aos="fade-right" data-aos-duration={800}>
                                    <div className="about__thumb-inner">
                                        <div className="about__thumb-image floating-content">
                                            <img className="dark" src="assets/images/about_the_pippin.png" alt="about-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-left" data-aos-duration={800}>
                                    <div className="about__content-inner">
                                        <h2>About The <span>Pippin</span></h2>
                                        <p> Pippin stands as a decentralized cryptocurrency designed to function as a utility token, and its maximum supply is capped at 20 million units, strategically distributed among potential Pippin holders through various processes.</p>
                                        <p>In its commitment to ensuring top-notch financial security and transparency, Pippin employs the finest and safest payment processes, positioning itself as a pioneer in the ever-evolving landscape of blockchain technology.</p>
                                        <p className="mb-0">We find ourselves in the midst of the internet era, with blockchain technology reshaping the world as we know it. Pippin emerges as a revolutionary force within the finance sector, boasting a rapidly growing community of individuals who are both knowingly and unknowingly becoming integral parts of this transformative journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
