import React from 'react'

export default function BuildYourCrypto() {
    return (
        <section className="about padding-top--style2 padding-bottom bg-color-3">
            <div className="container">
                <div className="about__wrapper">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="section-header mb-50">
                                <h2>Build your crypto portfolio with <span className="style2">Pippin</span></h2>
                                <p>Start your first trade with these easy steps.</p>
                            </div>
                            <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={800}>
                                <div className="about__content-inner">
                                    <div className="about__icon">
                                        <div className="about__icon-inner">
                                            <img src="./assets/images/verify_your_identity.png" alt="about-icon" className="dark" />
                                        </div>
                                    </div>
                                    <div className="about__content-details">
                                        <h5>Verify your identity</h5>
                                        <p className="mb-0">Complete the identity verification process to secure your account and transactions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={900}>
                                <div className="about__content-inner">
                                    <div className="about__icon">
                                        <div className="about__icon-inner">
                                            <img src="./assets/images/fund_your_account.png" alt="about-icon" className="dark" />
                                        </div>
                                    </div>
                                    <div className="about__content-details">
                                        <h5>Fund your account</h5>
                                        <p className="mb-0">Add funds to your crypto account to start trading crypto. You can add funds with a variety of payment methods.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="about__content about__content--style2 aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                <div className="about__content-inner">
                                    <div className="about__icon">
                                        <div className="about__icon-inner">
                                            <img src="./assets/images/start_trading.png" alt="about-icon" className="dark" />
                                        </div>
                                    </div>
                                    <div className="about__content-details">
                                        <h5>Start trading</h5>
                                        <p className="mb-0">You're good to go! Buy/sell crypto, set up recurring buys for your investments, and discover what Adverb has to offer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about__thumb about__thumb--style2 aos-init aos-animate" data-aos="fade-left" data-aos-duration={800}>
                                <div className="about__thumb-inner mt-30 mt-lg-0">
                                    <div className="about__thumb-image  text-center">
                                        <img src="./assets/images/build-your-crypto-portfolio-with-pippin.png" alt="about-image" className="dark" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about__shape about__shape--style2">
                <span className="about__shape-item about__shape-item--1"><img src="assets/images/others/2.png" alt="shape-icon" /></span>
            </div>
        </section>

    )
}
